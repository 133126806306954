<template>
  <div>
    <el-container>
      <!--      侧边栏-->
      <el-aside width="200px"><Aside /></el-aside>
      <el-container>
        <!--    头部-->
        <el-header class="el_header"><Header /></el-header>
        <!--      内容区域-->
        <el-main style="background: #F0F2F5;padding: 10px"><router-view /></el-main>
      </el-container>
    </el-container>
  </div>
  <div>
<!--    <div style="display: flex">-->
<!--&lt;!&ndash;      菜单栏&ndash;&gt;-->
<!--      <div style="display: flex;flex-direction:column;width: 270px;position:fixed">-->
<!--        <div style="height: auto;background: #20222A;color: #F7F9F9;font-size: 20px;padding: 13px;font-weight: bold">成华诊所药店后台管理平台</div>-->
<!--        <div style="background: #20222A"><Aside /></div>-->
<!--      </div>-->
<!--&lt;!&ndash;      头部和主体区域&ndash;&gt;-->
<!--      <div style="flex: 1;flex-direction:column;background: #F0F2F5;margin-left: 300px">-->
<!--        <div style="height: 60px"><Header /></div>-->
<!--        <div style="margin: 10px 10px 0 10px;"><router-view /></div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import Header from "@/components/Header";
import Aside from "@/components/Aside";

export default {
  name: "Layout",
  components: {
    Header,
    Aside
  }
}
</script>

<style scoped>

.el-header {
  left: 200px;
  width: 100%;
  position: absolute;
}
.el-aside {
  position: relative;
  overflow-x: hidden;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 60px;
  bottom: 0;
  overflow-y: hidden;
}

</style>