<template>
  <div style="height: 60px; line-height: 50px;background: #fff">
    <div style="flex: 1">
      <span style="height: auto;font-size: 20px;padding: 13px;font-weight: bold">成华诊所药店后台管理平台</span>
    </div>
<!--    <div style="width: 100px;cursor: pointer;margin-left: 500px" >-->
<!--      <el-popconfirm title="您确定退出吗？" @confirm="logout">-->
<!--        <template #reference>-->
<!--          <el-button link>-->
<!--            <span style="color: #2b333f">退出登录</span></el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
<!--    </div>-->
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Header",
  props: ['user'],
  data() {
    return {
      user:sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {},
    }
  },
  created() {

  },
  methods: {
    logout() {
      sessionStorage.removeItem('user')
      router.push('/login')
    }
  }
}
</script>

<style scoped>

</style>
