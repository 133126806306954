import {createRouter, createWebHistory} from 'vue-router'
import Layout from '../layout/Layout'

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/login',
        children: [
            // {path: 'home', name: 'Home', component: () => import('../views/Home')},
            {path: 'account', name: 'Account', component: () => import('../views/Account')},
            {path: 'category', name: 'Category', component: () => import('../views/Category')},
            {path: 'role', name: 'Role', component: () => import('../views/Role')},
            {path: 'therapy', name: 'Therapy', component: () => import('../views/Therapy')},
            {path: 'clinic', name: 'Clinic', component: () => import('../views/Clinic')},
            {path: 'patient', name: 'Patient', component: () => import('../views/Patient')},
            {path: 'doctor', name: 'Doctor', component: () => import('../views/Doctor')},
            {path: 'loginInfo', name: 'LoginInfo', component: () => import('../views/LoginInfo')},
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

//路由守卫
router.beforeEach((to, from, next) => {
    // if (to.path == "/home") {
    //     sessionStorage.removeItem("user");
    //     next({path: "/login"});
    // }
    if (!to.matched.length) {
        next()
    } else {
        next()
    }
})

export default router
