<template>
  <div>
    <div style="width: auto;height: 100px;background: #20222A;text-align: center;padding-top: 20px">
    <div style="color: white;font-size: 16px">
      欢迎您，{{ user.account }}
    </div>
      <div style="cursor: pointer;padding-top: 10px" >
        <el-popconfirm title="您确定退出吗？" @confirm="logout">
          <template #reference>
            <el-button link>
              <span style="color: white">退出登录</span></el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>
    <el-row>
      <el-col :span="12">
        <el-menu
            style="width: 251px;min-height: calc(100vh + 30px);"
            active-text-color="#ffd04b"
            background-color="#20222A"
            class="el-menu-vertical-demo"
            default-active="home"
            :default-openeds="['1','2']"
            text-color="#BBB9BB"
            router

        >
<!--          <el-menu-item index="home" v-if="user.role === 'ADMIN'">-->
<!--            <el-icon><document /></el-icon>-->
<!--            <span>首页</span>-->
<!--          </el-menu-item>-->
          <el-sub-menu index="1" v-if="user.role === 'ADMIN'">
            <template #title><el-icon><setting /></el-icon><span>账号信息管理</span></template>
            <el-menu-item index="account"><el-icon><document /></el-icon><span>账号信息</span></el-menu-item>
            <el-menu-item index="category"><el-icon><document /></el-icon><span>机构分类</span></el-menu-item>
            <el-menu-item index="role"><el-icon><document /></el-icon><span>角色信息</span>
            </el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="2" >
            <template #title><el-icon><setting /></el-icon><span>用户信息管理</span></template>
            <el-menu-item index="doctor"><el-icon><document /></el-icon><span>医生信息</span></el-menu-item>
            <el-menu-item index="patient"><el-icon><Coordinate /></el-icon><span>患者信息</span></el-menu-item>
          </el-sub-menu>
          <el-menu-item index="loginInfo">
            <el-icon><Film /></el-icon>
            <span>登录情况总览</span>
          </el-menu-item>
          <el-menu-item index="therapy">
            <el-icon><OfficeBuilding /></el-icon>
            <span>药店信息管理</span>
          </el-menu-item>
          <el-menu-item index="clinic">
            <el-icon><Film /></el-icon>
            <span>诊所信息管理</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Aside",
  data() {
    return {
      user: sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {},
    }
  },
  methods: {
    logout() {
      sessionStorage.removeItem('user')
      router.push('/login')
    }
  }
}
</script>

<style scoped>
.el-aside::-webkit-scrollbar {
  display: none;
}
</style>