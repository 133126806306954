import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css';
import './assets/css/gloabl.css'
import { createPinia } from 'pinia'


const pinia = createPinia()
const app =  createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.$baseUrl = process.env.VUE_APP_BASEURL

app.use(router)
app.use(pinia)
app.use(ElementPlus, {locale: zhCn})
app.mount('#app')
app.use(VideoPlayer)
